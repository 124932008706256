import { Skeleton, Col, Row, Space, Divider } from "antd";
import React, { ReactElement } from "react";
import './SkeletonLoading.scss';

interface Props {
    isHome?: boolean;
}

const SkeletonLoading: React.FC<Props> = ({
    isHome = false
}: Props): ReactElement => {

    const NavSkeleton = () => {
        return (
            <Row justify="space-between">
                <Col>
                    <Skeleton avatar={{size: 'large', shape: 'circle'}} title={{width: '10rem'}} paragraph={false} />
                </Col>
                <Col>
                    <Skeleton.Button active className="skeleton__mobileMenu" />
                    <Space size={45} className="skeleton__menu">
                        {[1, 2, 3, 4].map((i) => (
                            <Skeleton.Button key={i} active />
                        ))}
                    </Space>
                </Col>
                <Divider />
            </Row>
        );
    }

    const HeroSkeleton = () => {
        return (
            <Row className="skeleton__hero">
                <Col md={12} lg={10}>
                    <Skeleton title active />
                </Col>
                <Col md={12} lg={8} >
                    <Skeleton.Image style={{height: '25rem', width: '25rem'}} />
                </Col>
            </Row>
        );
    }

    if (isHome) {
        return (
            <div className="skeleton">
                <NavSkeleton />
                <HeroSkeleton />
                <Divider />
                <Space size={40}>
                    <Skeleton.Button active />
                    <Skeleton.Button active />  
                </Space>
                <Divider />
                <div className="max-container">
                    <Skeleton title={false} paragraph={{ rows: 6 }} />
                    <Skeleton paragraph={{ rows: 2 }} />
                    <Divider />
                    {[1, 2, 3].map((i) => (
                        <div key={i}>
                            <HeroSkeleton />
                            <Divider />
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className="skeleton">
                <NavSkeleton />
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Skeleton active />
                    </Col>
                    <Col span={18} >
                        <Skeleton active />
                    </Col>
                </Row>
            </div>
        );
    }
    
}

export default SkeletonLoading;