import React, { ReactNode } from 'react'
import { Card, Typography } from 'antd'
import PropTypes from 'prop-types';
// import 'antd/dist/antd.css';
import './MicroCard.scss';

interface cardProps {
    title: string;
    desc: string[];
    desktopDesc: string[];
    imgSrc: string;
    showButton: boolean;
    children: ReactNode;
}

const { Title, Paragraph } = Typography;

export default function MicroCard({ title, desc, desktopDesc, showButton, children, imgSrc }: cardProps) {
  return (
      <Card className="microCard" bodyStyle={{padding: "0px", height: '100%'}}>
          <div className="microCard__imageHolder__container">
            {imgSrc && <img src={imgSrc}/>}
          </div>
          <div className="microCard__description__container">
              <div className="microCard__text__container">
                <Title level={5}>{title}</Title>
                {desc.map((description, i) => (
                  <Paragraph type="secondary" key={i}>{description}</Paragraph>
                ))}
                <div className="microCard__text__container--desktop">
                  {desktopDesc.map((description, i) => (
                        <Paragraph type="secondary" key={i}>{description}</Paragraph>
                  ))}
              </div>
            </div>
            {showButton && children}
          </div>
      </Card>
    )
  }

  MicroCard.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.arrayOf(PropTypes.string),
    desktopDesc: PropTypes.arrayOf(PropTypes.string),
    imgSrc: PropTypes.string,
    showButton: PropTypes.bool,
    children: PropTypes.any,
  };
  
  MicroCard.defaultProps = {
    title: '',
    desc: [],
    desktopDesc: [],
    imgSrc: '',
    showButton: false,
    children: <></>
  };