import React, {ReactElement} from "react";
import { Typography } from 'antd';
import './Customization.scss';

const { Title, Paragraph } = Typography;

const EnterpriseComponents: React.FC = (): ReactElement => {
    return (
        <div className='customization'>
            <Title level={4}>Basic to advanced customization level</Title>
            <Paragraph>
                At New Wealth, we provide white-label mini-apps in which tailored frontend components are created for your app based on your corporate branding and design guidelines. Our Enterprise Components give your organization maximum customization flexibility and provide a consistent brand experience across different mobile devices and web browsers for your audience. After onboarding with New Wealth Enterprise Components, our implementation team will provide ready to use mini-apps which are already configured to match your requirements. Each of your Enterprise Components will have different configurable options which our implementation team will provide.
            </Paragraph>
            <Paragraph>
                Below is the list we can configure globally:
                <ul>
                    <li>Language(s)</li>
                    <li>Currencies</li>
                    <li>Date formats based on country</li>
                    <li>Number formats</li>
                    <li>CSS styling via JavaScript object (Theme Config)</li>
                </ul>
            </Paragraph>
        </div>
    );
}

export default EnterpriseComponents;
        
        