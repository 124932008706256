import * as React from "react";
import Logo from "assets/icons/logo-white.png";
import "./AppFooter.scss";
import { Typography } from "antd";

const { Text } = Typography;

const AppFooter: React.FunctionComponent = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="appFooter">
      <img src={Logo} width={80} />
      <Text>23 Church Street, Capital Square, Level 7, Singapore 049481</Text>
      <small>
        <Text>
          {`© ${year} Copyright New Wealth Experience Pte. Ltd. All Rights Reserved.`}
        </Text>
      </small>
    </footer>
  );
};

export default AppFooter;
