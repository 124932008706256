import React from "react";

const Home = React.lazy(() => import("./pages/Home"));
const Customization = React.lazy(() => import("./pages/Customization"));
const Integration = React.lazy(() => import("./pages/Integration"));
// const FAQs = React.lazy(() => import("./pages/FAQs"));

const AppRoutes = [
  {
    path: "/",
    component: <Home />,
    id: "home",
    label: "Home",
    exact: true,
  },
  {
    path: "/customization",
    component: <Customization />,
    id: "customization",
    label: "Customization",
    exact: false,
  },
  {
    path: "/integration",
    component: <Integration />,
    id: "integration",
    label: "Integration",
    exact: false,
  },
  // {
  //   path: "/faqs",
  //   component: <FAQs />,
  //   id: "faqs",
  //   label: "FAQs",
  //   exact: false,
  // },
];

export default AppRoutes;
