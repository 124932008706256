import EduCenter from 'assets/edu-center.png';
import FundCenter from 'assets/fund-center.png';
import PortfolioPlanner from 'assets/portfolio-planner.svg';
import { CheckOutlined, CheckCircleFilled } from '@ant-design/icons';


const data = [
  {
    id: 1,
    highlighterNote: "Mobile & Desktop Views",
    name: "Fund Center",
    desc: ["The white-label Fund Center is extremely intuitive and device-agnostic. It enables the compliant marketing, advisory and sales of financial instruments.",
      "With a coverage of functionalities ranging across investment screening (both ESG and non- ESG), discovering, analysing and monitoring, the objective of our Fund Center is to enable financial institutions to constantly deliver the best digital wealth UX for their end-investors, across all their channels.",],
    icon: CheckCircleFilled,
    boldFeatures: true,
    features: ["Investment Screener", "Fund Profile", "Investment Compare", "Rapid Search Bar"],
    actionType: "LIVE_DEMO",
    imgSrc: FundCenter,
    demoUrl: "https://endowus-fundcenter.newwealth.cloud/investment-funds-list",
  },
  {
    id: 1,
    highlighterNote: "Flexible Web Portal",
    name: "Education Center",
    desc: ["The white-label Knowledge Center is device-agnostic and able to manage a wide range of content about Investing and/or Sustainability: it can be deployed either for end-clients as an Education Center or for the frontliners of financial institutions as an eLearning Portal.",
      "Its content can be sourced from specialized provider(s) and internal departments (ex: news, research & insights, articles, videos, podcasts, events) and combined with engaging quizzes."],
    icon: "",
    boldFeatures: false,
    features: [],
    actionType: "LIVE_DEMO",
    imgSrc: EduCenter,
    demoUrl: "https://fundsingapore.com/education-center",
  },
  {
    id: 1,
    highlighterNote: "Newly Launched",
    name: "Portfolio Planner",
    desc: ["Our Hybrid Portfolio Planner application improves banker-customer conversations and delivers a better sales experience: device-agnostic it can be used on tablets, large touch-screen devices and smartphones too."],
    icon: CheckOutlined,
    boldFeatures: false,
    features: ["Simplify & minimize data requests by anticipating client requirements with AI",
      "Technology-augmented financial planning, goals-driven advising and holistic stress testing",
      "Deliver user-centered & personalized portfolio simulation",
      "Investment engine controls & compliance checks",
      "Share proposal to investor and link to e-application(s) for assisted onboarding"],
    actionType: "ASK_DEMO",
    imgSrc: PortfolioPlanner,
  },
];

export default data;
