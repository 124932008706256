import React, { ReactElement } from "react";
import { Row, Col, Divider, Typography, Button } from "antd";
import { ProductCard, MicroCard, ContactForm } from "components";
import { useNavigate } from "react-router-dom";
import "./EnterpriseComponents.scss";
import data from "./data";

const { Title, Paragraph } = Typography;

export interface IProductCard{
  id: string,
  type: string,
  title: string,
  heading?: string,
  desc: string[],
  desktopDesc?: string[],
  imgSrc: string,
  desktopImgSrc?: string,
  demoSrc?: string,
  demoUrl?: string,
  featureList?: string[],
  category?: string,
  disableAction?: boolean,
  actionType: string,
}

const EnterpriseComponents: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const handleOnClick = (card: IProductCard) => {
    navigate(`/product/${card.title.replace(/\s/g, '_')}`)
  }

  const CardButton = ({card}: {card: IProductCard}) => {
    const type = card.actionType;
    if (type === "LIVE_DEMO") {
      return (
        <div>
          <Button type="primary" onClick={() => handleOnClick(card)}>Live demo</Button>
        </div>
      )
    } else if (type === "ASK_DEMO") {
      return (
        <div>
          <ContactForm isModalVisible={false} />
        </div>
      )
    } else if (type === "SAMPLE_USE") {
      return (
        <div>
          <Button type="primary" onClick={() => handleOnClick(card)}>Sample use case</Button>
        </div>
      )
    }
    return <></>;
  }

  return (
    <div className="enterpriseCmpt max-container">
      <div className="enterpriseCmpt__header">
        <Title level={2}>Enterprise Components / Micro-frontends</Title>
        <Paragraph>
          With functionalities ranging across the 4 below categories for <u>investment screening &amp; discovering</u> (both ESG and non-ESG), instrument &amp; portfolio <u>analyzing</u>,&nbsp;
          <u>empowering &amp; advising</u>, and <u>market monitoring</u>, the main objective of NW Investment and Product Center is to enable financial institutions to constantly deliver the
          best digital wealth UX for their end-investors, across all their channels.
        </Paragraph>
      </div>
      {data && data.map((item) => (
        <div key={item.title} id={item.title.replace(/\s/g, '_')}>
          <Divider orientation="center">{item.title}</Divider>
          <Row gutter={[24, 24]} key={item.id} className="enterpriseCmpt__content">
            {/* <Col xs={24} sm={24} md={4} span={4}>
              <Title level={5} className="enterpriseCmpt__sectionHeader">{item.title}</Title>
            </Col> */}
            <Col xs={24} sm={24} md={24} span={24}>
              <Row gutter={[24, 24]} justify="center">
                {item.cardList && item.cardList.map((card) => (
                  <>
                    {card.type && card.type === 'PRODUCT_MINI_CART' &&
                      <Col xs={24} sm={24} md={8} span={8} key={item.id}>
                        <MicroCard
                          imgSrc={card.imgSrc}
                          title={card.title}
                          desc={card.desc}
                          desktopDesc={card.desktopDesc}
                          showButton={!!card.actionType}
                        >
                          {card.actionType && <CardButton card={card} />}
                        </MicroCard>
                      </Col>}
                    {card.type && card.type === 'PRODUCT_CART' &&
                      <Col xs={24} sm={24} md={12} span={12} key={card.id}>
                        <ProductCard
                          imgSrc={card.imgSrc}
                          desktopImgSrc={card.desktopImgSrc}
                          title={card.title}
                          desc={card.desc}
                          desktopDesc={card.desktopDesc}
                          featureList={card.featureList}
                          showButton={!!card.actionType}
                        >
                          {card.actionType && <CardButton card={card} />}
                        </ProductCard>
                      </Col>}
                  </>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default EnterpriseComponents;
