import React, { ReactElement } from "react";
import { Typography, Card } from "antd";
import "./ProductCard.scss";

const { Title, Text, Paragraph } = Typography;

interface Props {
  title?: string;
  desc?: string[];
  desktopDesc?: string[];
  imgSrc?: string;
  desktopImgSrc?: string;
  featureList?: string[];
  showButton?: boolean;
  children?: React.ReactNode;
}

const ProductCard: React.FC<Props> = ({
  title = "",
  desc = [],
  desktopDesc = [],
  imgSrc,
  desktopImgSrc = "",
  featureList = [],
  showButton = true,
  children = null,
}: Props): ReactElement => {

  return (
    <>
      <Card className="productCard" bodyStyle={{ height: '100%'}}>
        <Card.Grid hoverable={false} className="productCard__content">
          {desktopImgSrc ? 
            <>
            {imgSrc && <img className="productCard__mobileImage" src={imgSrc} />}
            {desktopImgSrc && <img className="productCard__desktopImage" src={desktopImgSrc} />}
            </> :
            imgSrc && <img src={imgSrc} />
          }
        </Card.Grid>
        <Card.Grid hoverable={false}  className="productCard__content">
          <div className="productCard__content__details">
            <div style={{ fontSize: '14px' }}>
              <Title level={5} className="productCard__content__title" style={{ lineHeight: '120%' }}>{title}</Title>
              {desc.map((description, i) => (
                <Paragraph type="secondary" italic className="productCard__content__text" key={i}>
                  {description}
                </Paragraph>
              ))}
              {desktopDesc.map((desc, i) => (
                <Paragraph italic type="secondary" className="productCard__content__text" key={i}>
                  {desc}
                </Paragraph>
              ))}
              <ul className="productCard__content__list">
                {featureList.map((feature, i) => (
                  <li key={i} style={{ fontSize: '14px' }}>
                    <Text type="secondary">{feature}</Text>
                  </li>
                ))}
              </ul>
            </div>
            {showButton && children}
          </div>
        </Card.Grid>
      </Card>
      <Card className="productCard productCard--onMobile" bodyStyle={{padding: "0px", height: '100%'}}>
          <div className="productCard--onMobile__imageHolder__container">
            {imgSrc && <img src={imgSrc}/>}
          </div>
          <div className="productCard--onMobile__description__container">
            <Title level={5}>{title}</Title>
            <Text type="secondary" italic className="productCard__content__text">{desc}</Text>
            <div className="productCard--onMobile__list__container">
              <ul className="productCard__content__list">
                {featureList.map((feature, i) => (
                  <li key={i} style={{ fontSize: '14px' }}>
                    <Text type="secondary">{feature}</Text>
                  </li>
                ))}
              </ul>
              {showButton && children}
            </div>
          </div>
      </Card>
    </>
  );
};

export default ProductCard;
