/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { PageHeader, Typography, Row } from "antd";
import "./HeroBanner.scss";

const { Title, Text } = Typography;

interface IHeroBannerProps {
  title?: string;
  alignTop?: boolean;
  content?: React.ReactNode;
  highlighterNote?: string;
  buttonPosition?: string;
  button?: React.ReactNode;
  imageSrc?: string;
}

const HeroBanner: React.FunctionComponent<IHeroBannerProps> = ({
  title = "Hero Banner Component",
  content = "Hero Banner Subtitle",
  alignTop = false,
  highlighterNote,
  buttonPosition = 'left',
  button,
  imageSrc,
}: IHeroBannerProps) => {
    
const Content = ({ children, image }: {children: React.ReactNode, image: React.ReactNode}) => (
  <Row className="heroBanner__content" style={alignTop ? {} : {alignItems: 'center'}}>
    <div className="heroBanner__content--text">{children}</div>
    <div className="heroBanner__content--image">{image}</div>
  </Row>
);

  return (
    <PageHeader className="heroBanner">
      <Content
        image={
            <img
            src={imageSrc}
            alt="content"
            width="100%"
            />
        }
      >
        {highlighterNote && <Text className="heroBanner__highlighterNote">{highlighterNote}</Text>}
        <Title level={1}>{title}</Title>
        {content}
        <br />
        <div style={buttonPosition === "left" ? {textAlign: "left"} : {textAlign: "right"}}>
          {button}
        </div>
      </Content>
  </PageHeader>
  );
};

export default HeroBanner;
