export const columns = [
    {
      title: 'Property',
      dataIndex: 'property',
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
        title: 'Default Value',
        dataIndex: 'default',
    },
  ];

export const data = [
{
    key: '1',
    property: 'fontFamily',
    type: 'string',
    description: 'Font type',
    default: 'Helvetica, sans-serif'
},
{
    key: '2',
    property: 'textColor',
    type: 'string',
    description: 'Body text color',
    default: '#454545'
},
{
    key: '3',
    property: 'textHeadingColor',
    type: 'string',
    description: 'Heading text color',
    default: '#101010'
},
{
    key: '4',
    property: 'primaryColor',
    type: 'string',
    description: 'Primary color of button, link, active state, etc...',
    default: '#FF0000'
},
{
    key: '5',
    property: 'secondaryColor',
    type: 'string',
    description: 'Secondary color of button, link, active state, etc...',
    default: '#E9E9E9'
},
{
    key: '6',
    property: 'h1Size',
    type: 'number',
    description: "Main title's font size (in pixel)",
    default: '32'
},
];

