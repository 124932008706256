import React, {ReactElement} from "react";
import { Typography } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './integration.scss';

const { Title, Paragraph } = Typography;

const NonDeveloper: React.FC = (): ReactElement => {
    const goToNative = () => {
        const anchors = document.getElementsByTagName('A');
        for (let i =0 ; i<anchors.length ; i++){
            if (anchors[i].innerHTML === 'Focus on native apps'){
                const target = anchors[i] as HTMLAnchorElement;
                target.click();
            }
        }
    }

    return (
        <div className="integration">
            <Title level={4}>For non-developers</Title>
            <Paragraph>
                There are many ways to integrate our Enterprise Components into your website or application. <span className='integration__text--bold'>While it supports traditional integration like using iFrame, we recommend injecting our bundled script that renders the mini-app into the div tag element.</span> By doing so, it improves user experience and UI responsiveness that can support mobile and desktop views.   
            </Paragraph>
            <Paragraph>
                Additionally, our Enterprise Components can also be injected into <a className="integration__anchor" onClick={goToNative}>Native apps using WebView</a>.
            </Paragraph>
            <br/>
            <Title level={5}>Injecting an Enterprise Component</Title>

            <Paragraph>
                Inserting a mini-app into a website only requires 4 steps.
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 1</span>: Insert the following script inside the  
                <span className="integration__text--code">{` <head></head> `}</span>tag element. 
            <SyntaxHighlighter language="htmlbars" style={atomOneDark}>
                {`<script src=“https://MINIAPP_NAME.newwealth.cloud/main.js”></script>` }  
            </SyntaxHighlighter>
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 2</span>: Get the token.
                </Paragraph>
            <Paragraph>
                New Wealth provides 3 different ways to obtain the token:<br/>
                <ol>
                    <li>
                        We recommend you to get the token through server side authentication by providing credentials and calling New Wealth API.
                    </li>
                    <li>
                        New Wealth generates a token with expiry date and some restrictions.
                    </li>
                    <li>
                        New Wealth generates a token without expiry date and some restrictions. New Wealth platform will control the validation of the token at runtime.
                    </li>
                </ol>       
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 3</span>: Inject the token into the webpage.<br/>
            </Paragraph>
            <Paragraph>
                To use the token, the parent app can pass the token as an attribute of a div element. There are 2 ways:
                <ol>
                    <li>
                    <span className="integration__text--code">{`<div id='min-app' token='eyJhbGci...'></div>`}</span>  
                    </li>
                    <li>
                        Parent app gets the token by calling New Wealth API in <span className="integration__text--emphasis">STEP 2</span> and injects the token into the <span className="integration__text--code">div</span> element at runtime using JavaScript.
                    </li>
                </ol> 
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 4</span>: Inside   
                <span className="integration__text--code">{` <body></body> `}</span>tag element, add this HTML div element with the id attribute.
            </Paragraph>
            <SyntaxHighlighter language="htmlbars" style={atomOneDark}>
                {`<div id=“miniapp_id”></div>` }  
            </SyntaxHighlighter>
            <br/>
            <Paragraph>
                <span className="integration__text--bold">Full example: </span> 
            </Paragraph>
            <SyntaxHighlighter language="htmlbars" style={atomOneDark} lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} wrapLines={true} >
            {`<!DOCTYPE html>
<html>
<head>
    <title>Your website</title> 
    <script src=“https://rapidsearch.newwealth.cloud/v1/main.js”></script> 
</head> 
<body> 
    <div id=“rapidSearch”></div> 
</body>
</html>` }  
            </SyntaxHighlighter>
        </div>
    );
}

export default NonDeveloper;
        