import React, { ReactElement } from "react";
import { Divider, Typography, Button } from "antd";
import "./StandardApplications.scss";
import data from "./data";
import { ContactForm, HeroBanner } from "components";

const { Title, Text, Paragraph } = Typography;

const StandardApplications: React.FC = (): ReactElement => {

  const handleOnClick = (demoUrl: string) => {
    demoUrl && window.open(demoUrl);
  }

  return (
    <div className="standardApps max-container">
      <div className="standardApps__header">
        {/* <Text className="standardApps__header__highlighterNote">Full-featured Platform</Text> */}
        <Title className="standardApps__header__name" level={2}>Standard Applications</Title>
        <Paragraph>
          Each standard app is made by combining several ready-made Enterprise Components, powered by New Wealth DataHub for investment data management. They can be deployed with almost no or low level of customization.
        </Paragraph>
        <Paragraph>Thus the standard applications enable the shortest time-to-market, save you hundreds of man-days initially and benefit from active maintenance service with constant improvements.</Paragraph>
      </div>
      {/* <Divider /> */}
      {data && data.map((item, idx) => (
        <div key={idx}>
          <HeroBanner 
            alignTop={idx === 1}
            title={item.name}
            key={item.id}
            content={
            <>
                {item.desc.map((description, i) => (
                  <Paragraph key={i}>{description}</Paragraph>
              ))}
              <ul className="standardApps__appContainer__features">
                {item.features && item.features.map((feature, i) => (
                  <li key={i} className="standardApps__appContainer__features__feature">
                      <item.icon />
                    <Text strong={item.boldFeatures}>{feature}</Text>
                  </li>
                ))}
              </ul>
            </>}
            button={item.actionType && item.actionType === "LIVE_DEMO"
              ? <Button size="large" type="primary" onClick={()=>handleOnClick(item.demoUrl || '')}>Live demo</Button>
              : item.actionType === "ASK_DEMO" && <ContactForm isModalVisible={false}/>}
            imageSrc={item.imgSrc}
            highlighterNote={item.highlighterNote}
          />
          {idx !== 2 ? <Divider /> : null}
        </div>
      ))}
    </div>
  );
};

export default StandardApplications;
