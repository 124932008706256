import React, {ReactElement} from "react";
import { Typography } from 'antd';
import './integration.scss';

const { Title, Paragraph } = Typography;

const Overview: React.FC = (): ReactElement => {
    return (
        <div className="integration">
            <Title level={3}>Integration of Enterprise Components / Micro-frontends</Title>
            <Title level={4}>What is micro-frontend?</Title>
            <Paragraph>
                Micro-frontend is the decomposition of an app into smaller, individual, semi-independent mini-apps working loosely together.
            </Paragraph>
            <Paragraph>
                In a micro-frontend architecture, development teams independently build and deploy mini frontend apps. These mini-apps are combined by a parent frontend application that acts as a container to retrieve, display, and integrate the various mini-apps. In this model, the user interacts with what appears to be a single app. In reality, they are interacting with several independent mini-apps, published by different teams.
            </Paragraph>
            <Paragraph>
                New Wealth’s Enterprise Components use the concept of micro-frontends, making it simple to integrate and easy to understand and maintain — for developers and non-developers.
            </Paragraph>
        </div>
    );
}

export default Overview;
        