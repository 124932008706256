import React, {ReactElement} from "react";
import { Typography } from 'antd';
import './integration.scss';

const { Title, Paragraph } = Typography;

const Hosting: React.FC = (): ReactElement => {
    return (
        <div className="integration">
        <Title level={4}>Our Hosting Services</Title>
            <Paragraph>
            New Wealth offers 2 ways to deploy mini-apps for your organization.    
            <ol>
            <br/>
                <li>
                    New Wealth lets you leverage the cloud to develop and release new services faster than ever. We create a subdomain for your organization, such as https://clientname-miniapp-api.newwealth.cloud, and deploy mini-apps on the New Wealth Cloud of your preferred choice including for instance AWS, GCP, or Azure. 
                </li>
                <br/>
                <li>
                    Alternatively, New Wealth can deploy mini-apps on your own server.
                </li>
            </ol>
            </Paragraph>
        </div>
    );
}

export default Hosting;
        