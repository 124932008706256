/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Menu, Button } from "antd";
import AppRoutes from "AppRoutes";
// import { Typography } from "antd";
import MenuIcon from 'assets/icons/menu.svg';
import CloseIcon from 'assets/icons/close.svg';
import Catalogue from 'assets/catalogue.svg';
import "./AppNavbar.scss";

// const { Title } = Typography;

interface IAppNavbarProps {
  title?: string;
  logoSrc?: string;
}

const AppNavbar: React.FunctionComponent<IAppNavbarProps> = ({
  title = "Catalogue",
  logoSrc,
}: IAppNavbarProps) => {
  const location = useLocation();
  const params = useParams();
  const [current, setCurrent] = useState("");
  const [isToggledMobile, setIsToggledMobile] = useState(false);
  const isNotHomepage = location.pathname !== "/";

  console.log("location", location, params);

  useEffect(() => {
    if (location.pathname !== "/") {
      setCurrent(location.pathname.slice(1));
    } else {
      setCurrent("home");
    }
  }, [location.pathname]);

  return (
    <div className={`app-navbar ${isNotHomepage ? 'app-navbar--withShadow' : ''}`} id='app-navbar'>
      <div className="app-navbar__logo">
        {logoSrc && (
          <a href="https://www.newwealth.com.sg" target="_blank" rel="noreferrer">
            <img
              src={logoSrc}
              alt=""
              style={{ maxWidth: "64px", height: "auto" }}
            />
          </a>
        )}
        {title && (
          <Link to="/">
            <img 
              src={Catalogue}
              alt="catalogue"
            />
          </Link>
          // <div className="app-navbar__logo__title">
          //   <Link to="/">
          //     <Title level={3} style={{ fontFamily: 'Gill Sans', fontSize: '29px', letterSpacing: '-1px' }}>{title}</Title>
          //     <small>OF FUTURE-PROOFED MINI-APPS</small>
          //   </Link>
          // </div>
        )}
      </div>
      <span className="spacer" />
      <Button
        type="link"
        className="app-navbar__toggleMenuBtn"
        onClick={() => setIsToggledMobile(!isToggledMobile)}
        size="small"
        icon={isToggledMobile ? <img src={CloseIcon} /> : <img src={MenuIcon} />}
      />
      <nav className={`app-navbar__nav ${isToggledMobile ? 'toggled--onMobile' : ''}`}>
        <Menu
          theme="light"
          mode={isToggledMobile ? 'vertical' : 'horizontal'}
          selectedKeys={[current]}
          // style={{ maxHeight: 50 }}
        >
          {AppRoutes.map((item) => (
            <Menu.Item key={item.id}>
              <Link to={item.path} title={item.label}>
                {item.label}
              </Link>
            </Menu.Item>
          ))}
           <Menu.Item onClick={()=>window.open("https://www.newwealth.com.sg/wealthtech-solutions#investment-datahub")}>
                DataHub
            </Menu.Item>
        </Menu>
      </nav>
    </div>
  );
};

export default AppNavbar;
