import React, {ReactElement} from "react";
import { Typography } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './integration.scss';

const { Title, Paragraph } = Typography;

const Developer: React.FC = (): ReactElement => {
    return (
        <div className="integration">
            <Title level={4}>For developers</Title>
            <Paragraph>
                Integration for developers gives more flexibility to further customize the look and feel of the mini-app provided that the parent app is using a webpack and a module-federation plugin to manage the bundle and micro-frontend remotes.
            </Paragraph>
            <br/>
            <Title level={5}>Injecting an Enterprise Component</Title>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 1</span>: Webpack Config.   
            </Paragraph>
            <Paragraph>
                To use our mini-app, initiate the remote in the webpack configuration under Module Federation plugin declaration. Add one or more mini-app’s published URL like the one below.
            </Paragraph>
            <SyntaxHighlighter language="yaml" style={atomOneDark}>
                {`...
    plugins: [
    new ModuleFederation({ 
    name: “YOUR_APP_NAME”, 
    filename: “remoteEntry.js”, 
    remotes: { 
        search_app: “search_app@https://MINI_APP_DOMAIN/remoteEntry.js”, 
        esg_app: “esg_app@https://MINI_APP_DOMAIN/remoteEntry.js”, 
    } 
    }) 
    ], 
    …` }  
            </SyntaxHighlighter>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 2</span>: Get the token.
                </Paragraph>
                <Paragraph>
                New Wealth provides 3 ways to obtain the token:<br/>
                <ol>
                    <li>
                        We recommend you to get the token through server side authentication by providing credentials and calling New Wealth API.
                    </li>
                    <li>
                        New Wealth generates a token with expiry date and some restrictions.
                    </li>
                    <li>
                        New Wealth generates a token without expiry date and some restrictions. New Wealth platform will control the validation of the token at runtime.
                    </li>
                </ol> 
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 3</span>: Inject the token into the webpage.
                </Paragraph>
                <Paragraph>
                To use the token, the parent app can pass the token as an attribute of the component. There are 2 ways:
                <ol>
                    <li>
                    <span className="integration__text--code">{`<mini-app id='mini-app' token='eyJhbGci...'></mini-app>`}</span>  
                    </li>
                    <li>
                        Parent app gets the token by calling New Wealth API in <span className="integration__text--emphasis">STEP 2</span> and injects the token into <span className="integration__text--code">div</span> element at runtime using JavaScript.
                    </li>
                </ol> 
            </Paragraph>
        </div>
    );
}

export default Developer;
        