import React from "react";
import { Routes, Route } from "react-router-dom";
import AppRoutes from "AppRoutes";

import "shared/antd/antdTheme.less";
import { SkeletonLoading } from "components";

const ProductDetail = React.lazy(() => import("./pages/ProductDetail"));

function App() {
  return (
    <Routes>
      {AppRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={
            <React.Suspense fallback={<SkeletonLoading isHome={route.id === 'home'} />}>
              {route.component}
            </React.Suspense>
          }
          index={route.exact}
        />
      ))}

      <Route
          path="/product/:id"
          element={
            <React.Suspense fallback={<SkeletonLoading isHome={false} />}>
              <ProductDetail/>
            </React.Suspense>
          }
        />
   </Routes>
  );
}

export default App;
