import React, { Children, ReactElement, useState, useEffect } from "react";
import { Menu } from "antd";
import "./Sidebar.scss";

import { Anchor } from 'antd';

const { Link } = Anchor;

interface Props {
    categories?: string[],
    navLabels?: string[],
    children?: React.ReactNode,
}

const Sidebar: React.FC<Props> = ({
    categories=[],
    navLabels=[],
    children,
}: Props): ReactElement => {
    const arrayChildren = Children.toArray(children);
    const [offset, setOffset] = useState(0);
    const [activeSection, setActiveSection] = useState([categories[0]]);
    const [isOnMobile, setIsOnMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const header = document.querySelector('.nwLayout__header') as HTMLElement;
            if (header !== null) {
                setOffset(header.offsetHeight)
            }
            if (document.body.clientWidth < 420){
                setIsOnMobile(true)
            } else {
                setIsOnMobile(false)
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const handleClick = (
        e: React.MouseEvent<HTMLElement>,
        link: {
          title: React.ReactNode;
          href: string;
        },
      ) => {
        e.preventDefault();
        setActiveSection([link.href.slice(1)])
      };

    const handleOnChange = (currentActiveLink: string) => {
        if (currentActiveLink == ''){
            setActiveSection([categories[0]])
        }
        else{
            setActiveSection([currentActiveLink.slice(1)])
        }
    }

    return (
        <div className="sidebar">
            <nav>
            {
            isOnMobile? 
            <Anchor affix={false} onClick={handleClick} offsetTop={offset+45} onChange={handleOnChange}>
               {navLabels.map((label, i) => 
                    <Link href={`#${categories[i]}`} key={`${categories[i]}`}  title = {label}/>       
                )}
            </Anchor>  
            : 
            <Anchor affix={false} onClick={handleClick} offsetTop={offset} onChange={handleOnChange}>
            <Menu theme="light"  mode="inline" selectedKeys={activeSection}>
                    {navLabels.map((label, i) => 
                        <Menu.Item key={`${categories[i]}`} >
                            <Link href={`#${categories[i]}`}  title = {label}/>
                        </Menu.Item>
                    )}
                </Menu>
            </Anchor>  
        }  
        </nav>    
         <main>
                {
                    children && 
                    arrayChildren.map((child, i)=> <section id={categories[i]} key={`content-${categories[i]}`}>{child}</section>)
                }
            </main>  
        </div>
    );
};

export default Sidebar;
