import React, {ReactElement} from "react";
import { Typography } from 'antd';
import './Customization.scss';

const { Title, Paragraph } = Typography;

const Overview: React.FC = (): ReactElement => {
    return (
        <div className='customization'>
            <Title level={3}>Customization of Enterprise Components / Micro-frontends</Title>
            <Title level={4}>What is customization?</Title>
            <Paragraph>
                Basic customization ensures that all the frontend interfaces will be uniformly branded with your corporate branding including corporate colors, logos, font, and more. With New Wealth, you can further customize your mini-apps to align with your corporate design guidelines and ensure consistent appearance for all your apps. 
            </Paragraph>
            <Paragraph>
                The objective of customization is to provide seamless customer experience on all channels and through all stages of the customer journey.
            </Paragraph>
        </div>
    );
}

export default Overview;
        
        