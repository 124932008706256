import React, {ReactElement} from "react";
import { Typography, Table } from 'antd';
import { columns, data } from './data';
import './Customization.scss';

const { Title, Paragraph } = Typography;

const ThemeConfig: React.FC = (): ReactElement => {
    return (
        <div className="customization">
        <Title level={4}>An example with a specific Enterprise Component</Title>
        <Paragraph>
            New Wealth offers a Rapid Search bar component that can be fully customized to reflect your corporate branding.
        </Paragraph>
        <br/>
        <Paragraph className="customization__text--center customization__text--underline">
             Sample list of customization options for the Rapid Search component
        </Paragraph>

        <Table
         columns={columns}
         dataSource={data}
         bordered
         // title={() => 'Header'}
         scroll={{ x: true }}
        //  footer={() => footer}
     />
        <Paragraph>
            Further properties will be provided after onboarding with New Wealth.
        </Paragraph>
    </div>
    );
}

export default ThemeConfig;
        
