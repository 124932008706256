import InvestmentScreener from 'assets/investment-screener.svg';
import InvestmentCompare from 'assets/investment-compare.svg';
import FundProfile from 'assets/fund-profile.svg';
import PortfolioAnalysis from 'assets/portfolio-analysis.svg';
import RapidSearch from 'assets/rapid-search.svg';
import ESGProfiler from 'assets/esg-profiler.svg';
import RiskProfiler from 'assets/risk-profiler.svg';
import FundReport from 'assets/fund-report.svg';
import FundScreener from 'assets/fund-screener.svg';
import InvestmentCalculator from 'assets/investment-calculators.svg';
import PortfolioComparison from 'assets/portfolio-comparison.svg';
import PortfolioExplorer from 'assets/portfolio-explorer.svg';
import StockReport from 'assets/stock-report.svg';
import SustainabilityScreener from 'assets/sustainability-screener.svg';
import RapidSearchDemo from 'assets/screenshots/rapid-search-demo.png';
import SustainabilityScreenerDemo from 'assets/screenshots/sustainability-screener-demo.png';
import InvestmentScreenerDemo from 'assets/screenshots/investment-screener-demo.png';
import FundProfileDemo from 'assets/screenshots/fund-profile-demo.png';
import InvestmentCompareDemo from 'assets/screenshots/investment-compare-demo.png';
import PortfolioAnalysisDemo from 'assets/screenshots/portfolio-analysis-demo.png';
import RiskToleranceDemo from 'assets/screenshots/risk-tolerance-demo.png';
import SustainabilityProfilerDemo from 'assets/screenshots/sustainability-profiler-demo.png';
import MarketNews from 'assets/market-news.svg';
import Alerts from 'assets/alerts.svg';
import Research from 'assets/research-insights.svg';
import Event from 'assets/event.svg';
import StockReportDesktop from 'assets/stock-report-desktop.svg';
import FundReportDesktop from 'assets/fund-report-desktop.svg';
import InsightsResearchDemo from 'assets/screenshots/insights-research-demo.png';
import EventsDemo from 'assets/screenshots/events-demo.png';

const exploreDiscoverCards = [
  {
    id: 'exploreDiscoverCards-1',
    type: "PRODUCT_CART",
    title: "Investment Screener",
    heading: "With investment screener, you can filter investments to suit your requirements. It can be as simple or as complex as your use demands.",
    desc: [`Search products for both New‑to‑Investment and more sophisticated investors.`],
    desktopDesc: [],
    imgSrc: InvestmentScreener,
    demoSrc: InvestmentScreenerDemo,
    demoUrl: 'https://www.fundsingapore.com/fund-library',
    featureList: ["Card view for mobiles", "List view for desktops"],
    // featureList: ["Rapid Search", "Fund Cards", "Fund Tables"],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'exploreDiscoverCards-2',
    type: "PRODUCT_CART",
    title: "Fund Profile",
    heading: "Fund profile summarizes key information about a fund including the fund's investment objective, risk level, costs, past performance and holdings details. The overview is displayed in clear and concise interface.",
    desc: ["Dynamic factsheet for pleasant product discovery."],
    desktopDesc: [],
    imgSrc: FundProfile,
    demoSrc: FundProfileDemo,
    demoUrl: 'https://endowus.com/investment-funds-list/pimco-gis-commodity-real-return-fund-IE00B1BXJ858',
    featureList: [
      "Promote transparency",
      "Combine market data with internal content & datapoints",
    ],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'exploreDiscoverCards-3',
    type: "PRODUCT_MINI_CART",
    title: "Rapid Search Bar",
    heading: "Search for investments you are looking for quickly. By configuring keywords, results can be limited to data relevant to the user.",
    desc: ["Enable easy search anywhere"],
    desktopDesc: [],
    imgSrc: RapidSearch,
    demoSrc: RapidSearchDemo,
    // demoUrl: "https://www.rapid-search.newwealth.cloud/",
    featureList: [],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'exploreDiscoverCards-4',
    type: "PRODUCT_MINI_CART",
    title: "Sustainability Screener",
    heading: "Sustainability screener allows you to investigate and evaluate the sustainability performance of investments to mitigate risk and align investments with your goals.",
    desc: ["Explore by ESG metrics"],
    desktopDesc: [],
    imgSrc: SustainabilityScreener,
    desktopImgSrc: "",
    demoSrc: SustainabilityScreenerDemo,
    demoUrl: 'https://esg.fundsingapore.com/preferences',
    featureList: [],
    // featureList: ['Sustainability attributes', 'ESG risk', 'Carbon risk'],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'exploreDiscoverCards-5',
    type: "PRODUCT_MINI_CART",
    title: "Fund House Screener",
    desc: ["Discover by AM company"],
    desktopDesc: [],
    imgSrc: FundScreener,
    desktopImgSrc: "",
    featureList: [],
    actionType: "ASK_DEMO",
  },
];

const analyzeCards = [
  {
    id: 'analyzeCards-1',
    type: "PRODUCT_CART",
    title: "Investment Compare",
    desc: ["Side by side comparison of investment products."],
    desktopDesc: [],
    heading: "You can define the universe of investments you want to analyze, the groups of data which are displayed, and the data points for each group. Investment compare enables you to help you make more-informed decisions.",
    imgSrc: InvestmentCompare,
    desktopImgSrc: "",
    demoSrc: InvestmentCompareDemo,
    demoUrl: 'https://www.fundsingapore.com/fund-library/fund-compare?ids=F0HKG070BV,F0HKG062T0,F0HKG062HT',
    featureList: [
      "Performance Chart",
      "Allocations",
      "Risk & Returns",
      "Dividends and Fees",
    ],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'analyzeCards-2',
    type: "PRODUCT_CART",
    title: "Portfolio Analysis",
    heading: "Portfolio analysis leverages Morningstar’s methodologies and calculations to provide information at the security level of a portfolio’s holdings. It analyses a portfolio with various metrics you would like to show in one place.",
    desc: ["Create a new & custom portfolio and examine it using a range of analytics provided by Morningstar X-Ray APIs."],
    desktopDesc: [],
    imgSrc: PortfolioAnalysis,
    desktopImgSrc: "",
    demoSrc: PortfolioAnalysisDemo,
    demoUrl: 'https://www.fundsingapore.com/fund-library/fund-portfolio?ids=0P0000A4T6,F00000JPVA,F0HKG062J1',
    featureList: [],
    // disableAction: true,
    actionType: "LIVE_DEMO",
  },
  {
    id: 'analyzeCards-3',
    type: "PRODUCT_CART",
    title: "Portfolio Explorer",
    desc: ["Search and discover model portfolios and asset allocations.", "Highlight how each of them differentiate and has been constructed."],
    desktopDesc: [],
    imgSrc: PortfolioExplorer,
    desktopImgSrc: "",
    actionType: "ASK_DEMO",
  },
  {
    id: 'analyzeCards-4',
    type: "PRODUCT_CART",
    title: "Portfolio Comparison",
    desc: ["Compare a current and a proposed portfolio using a range of analytics provided by Morningstar X-Ray APIs."],
    desktopDesc: [],
    imgSrc: PortfolioComparison,
    desktopImgSrc: "",
    actionType: "ASK_DEMO",
  },
];

const empowerAdviceCards = [
  {
    id: 'empowerAdviceCards-1',
    type: "PRODUCT_CART",
    title: "Stock Report",
    heading: "Lorem ipsum dolor Excepteur sint occaecat sunt",
    desc: ["View research, fundamental data and visualizations for a stock."],
    desktopDesc: ["The information provided helps the end-investor decides whether it is the appropriate time to buy or sell."],
    imgSrc: StockReport,
    desktopImgSrc: StockReportDesktop,
    featureList: [],
    actionType: "ASK_DEMO",
  },
  {
    id: 'empowerAdviceCards-2',
    type: "PRODUCT_CART",
    title: "Fund Report for Relationship Managers",
    heading: "Lorem ipsum dolor Excepteur sint occaecat sunt",
    desc: ["Assess a fund with in-depth information on a need-to-know basis.", "This report can be applied to open-ended funds, closed-ended funds and ETFs."],
    desktopDesc: ["The information provided helps the RM recommends whether it is the appropriate time to subscribe or redeem."],
    imgSrc: FundReport,
    desktopImgSrc: FundReportDesktop,
    featureList: [],
    actionType: "ASK_DEMO",
  },
  {
    id: 'empowerAdviceCards-3',
    type: "PRODUCT_MINI_CART",
    title: "Sustainability Profiler",
    heading: "Sustainability profiler allows you access the preferences of an investors through a series of interactive questionnaires.",
    desc: ["Ascertain client’s preferences"],
    desktopDesc: [],
    imgSrc: ESGProfiler,
    desktopImgSrc: "",
    demoSrc: SustainabilityProfilerDemo,
    demoUrl: 'https://esg.fundsingapore.com/',
    featureList: [],
    // featureList: ["Environmental concerns", "Client goals (returns, values, impact)"],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'empowerAdviceCards-4',
    type: "PRODUCT_MINI_CART",
    title: "Risk - Tolerance Profiler",
    heading: "The Risk profiler is used to assess a client’s attitude to risk and capacity for loss through psychological questionnaires.",
    desc: ["Assess individual risk profile"],
    desktopDesc: [],
    imgSrc: RiskProfiler,
    desktopImgSrc: "",
    demoSrc: RiskToleranceDemo,
    demoUrl: 'https://www.fundsingapore.com/profilers/risk-tolerance',
    featureList: [],
    // featureList: ["Prior investment experience", "Financial knowledge", "Client goals (time horizon, required return, liquidity)"],
    actionType: "LIVE_DEMO",
  },
  {
    id: 'empowerAdviceCards-5',
    type: "PRODUCT_MINI_CART",
    title: "Investment Calculators",
    desc: ["Personalize simulations"],
    desktopDesc: [],
    imgSrc: InvestmentCalculator,
    desktopImgSrc: "",
    featureList: [],
    actionType: "ASK_DEMO",
  },
];

const marketMonitoringCards = [
  
  {
    id: 'marketMonitoringCards-1',
    type: "PRODUCT_CART",
    title: "Market News",
    heading: "Lorem ipsum dolor Excepteur sint occaecat sunt",
    desc: ["Monitor and read third-party news about specific investments.", "The news feed is searchable and can be filtered by multiple dimensions (ex: asset classes, corporate actions, share buy backs, …)."],
    desktopDesc: [],
    imgSrc: MarketNews,
    desktopImgSrc: "",
    featureList: [],
    actionType: "ASK_DEMO",
  },
  {
    id: 'marketMonitoringCards-2',
    type: "PRODUCT_CART",
    title: "Alerts (Price, Dividend)",
    heading: "Lorem ipsum dolor Excepteur sint occaecat sunt",
    desc: ["Get updated by receiving personalized notifications via email, in-app or alert popup regarding latest prices and dividends."],
    desktopDesc: [],
    imgSrc: Alerts,
    desktopImgSrc: "",
    featureList: [],
    actionType: "ASK_DEMO",
  },
  {
    id: 'marketMonitoringCards-3',
    type: "PRODUCT_CART",
    title: "Insights & Research",
    heading: "Lorem ipsum dolor Excepteur sint occaecat sunt",
    desc: ["Personalized access to articles, videos and podcasts, to deliver latest insights from internal & external financial specialists."],
    desktopDesc: [],
    imgSrc: Research,
    demoSrc: InsightsResearchDemo,
    desktopImgSrc: "",
    featureList: [],
    disableAction: true,
    actionType: "SAMPLE_USE",
  },
  {
    id: 'marketMonitoringCards-4',
    type: "PRODUCT_CART",
    title: "Events",
    heading: "Lorem ipsum dolor Excepteur sint occaecat sunt",
    desc: ["Access to curated virtual events and registration for physical events proposed by a financial institution."],
    desktopDesc: [],
    imgSrc: Event,
    demoSrc: EventsDemo,
    desktopImgSrc: "",
    featureList: [],
    disableAction: true,
    actionType: "SAMPLE_USE",
  },
];

const data = [
  {
    id: 1,
    title: "Explore and Discover",
    cardList: exploreDiscoverCards,
  },
  {
    id: 2,
    title: "Analyze",
    cardList: analyzeCards,
  },
  {
    id: 3,
    title: "Empower and Advice",
    cardList: empowerAdviceCards,
  },
  {
    id: 4,
    title: "Market Monitoring",
    cardList: marketMonitoringCards,
  },
];

export default data;
