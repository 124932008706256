import React, {ReactElement} from "react";
import { Typography } from 'antd';
import './integration.scss';

const { Title, Paragraph } = Typography;

const Deployment: React.FC = (): ReactElement => {
    return (
       <div className="integration">
            <Title level={4}>Independent Deployments of Mini-Apps</Title>
            <Paragraph>
                Independent deployment is one of the key benefits of the micro frontend architecture. By reducing the scope of every individual deployment, deployment of our mini-apps will not affect the entire app. 
            </Paragraph>

            <Paragraph>
                Our Enterprise Components support independent deployment to help you mitigate the risks associated with deployments and achieve continuous and agile delivery. 
            </Paragraph>
        </div>
    );
}

export default Deployment;
        