import React from "react";
import { Typography } from "antd";
import "./Introduction.scss";

const { Paragraph, Title } = Typography;

const Introduction = () => {
    return (
        <div className="introduction">
            <section>
                <Title level={2}>Introduction</Title>
                <Paragraph>
                    The New Wealth Catalogue of future-proofed mini-apps encompass a comprehensive list of ready-made and best-in-class widgets (so called ‘Enterprise Components’ or ECs) that
                    are delivered to our B2B clients as modern micro-frontends. It covers a wide range of functionalities and tools - to engage with self-directed investors and/or empower
                    relationship managers – that are grouped under ‘NW Investment &amp; Product Center’.
                </Paragraph>
                <Paragraph>
                    These tech-forward Enterprise Components can be delivered individually (i.e. to accelerate in-house development projects for financial institutions), bundled as standard apps
                    (i.e. to get the shortest time-to-market, and best value for money) or used as accelerators to deliver unique mobile-first and desktop-responsive applications with high-level
                    of digital engagement.
                </Paragraph>
                <Paragraph>
                    The ECs fast load and display market data from Morningstar or other third-party providers, while all the product-related and content data can be enriched by the financial
                    institutions themselves (and their product partners) using New Wealth DataHub solution.
                </Paragraph>
            </section>
        </div>
    )
};

export default Introduction;