import React, { BaseSyntheticEvent, ReactElement, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Input, message } from 'antd';
import './ContactForm.scss'

interface inputProps {
    fullName?: string, 
    email?: string, 
    mobile?: string, 
    message?: string
}
interface Props {
    isModalVisible?: boolean,
    onSubmit?: (input: inputProps) => void
}

const API_URL = "https://users.nw-cloud.com/api/v1/demo/contact"

const ContactForm: React.FC<Props> = ({
    isModalVisible=false,
    onSubmit
}: Props): ReactElement => {
    const [form] = Form.useForm();
    const [input, setInput] = useState({fullName: '', email: '', mobile: '', message:''})
    const [visible, setVisible] = useState(isModalVisible);

    const submitForm = async (data: string) =>{
        const config = {
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios.post(API_URL, data, config).then(() => {
            success()
        }).catch(()=> {
            error()
        });
    };
    
    const success = () => {
        message.success("We've received your message.");
        setVisible(false);
      };

    const error = () => {
        message.error("Your message was not delivered.");
    };

    const handleSubmit = () => {
        if (input.fullName == '' || input.email == ''|| input.message == ''){
            error();
            return;
        }
        const data = JSON.stringify({"email":input.email,
            "fullName":input.fullName,
            "phone":input.mobile,
            "msg":input.message});

        submitForm(data)
        onSubmit && onSubmit(input);
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleShowModal = () => {
        setVisible(true)
    }

    const handleChange = (e: BaseSyntheticEvent) => {
        const {name, value} = e.target
        setInput({... input, [name]: value})
    }

  return (
      <>
        <Button onClick={handleShowModal} danger>Ask for demo </Button>
       { visible &&
        <Modal className='modal' title="Contact us for a demo" visible={visible} 
        footer={[
            <Button key="1" type="link" danger onClick={handleCancel}>Cancel</Button>,
            <Button key="2" type='primary' danger onClick={handleSubmit}>Submit</Button>,
        ]}
        onCancel={handleCancel}
        >
        <Form
            form={form}
            layout="vertical"
            className='modal__form'
            //   initialValues={{ requiredMarkValue: requiredMark }}
            //   onValuesChange={onRequiredTypeChange}
            //   requiredMark={requiredMark}
        >
            <div>
            <Form.Item className='modal__form__item modal__form__item--required' label="FULL NAME"  >
                <Input name='fullName' onChange={handleChange} value={input.fullName}/>
            </Form.Item>
            </div>
            <div className='modal__innerContainer'>
            <Form.Item className='modal__form__item modal__form__item--required' label="EMAIL ADDRESS" rules={[{ required: true, message: "This field is required"  }]}>
                <Input name='email' onChange={handleChange} value={input.email}/>
            </Form.Item>
            <Form.Item className='modal__form__item' label="MOBILE" >
                <Input name='mobile' onChange={handleChange} value={input.mobile}/>
            </Form.Item>
            </div>
            <div>
            <Form.Item className='modal__form__item modal__form__item--required' label="MESSAGE">
                <Input.TextArea name='message' rows={4} onChange={handleChange} value={input.message}/>
            </Form.Item>
            </div>
        </Form>
  </Modal>
  }
  </>
  );
};

export default ContactForm;
