/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Typography, Button, Divider  } from "antd";
import { CheckCircleFilled } from '@ant-design/icons';
import "./ProductBanner.scss";

const { Title, Paragraph } = Typography;

export interface IProductBannerProps {
  title?: string;
  desc?: string[];
  featureList?: string[];
  demoSrc?: string;
  heading?: string;
  demoUrl?: string;
  disableAction?: boolean;
}

function ProductBanner(
  { title, desc, featureList, demoSrc, demoUrl, disableAction = false }: IProductBannerProps) {
    const [loaded, setLoaded] = useState(false);
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
    const ref = useRef<HTMLInputElement>();

    const handleFocus = (isFocus: boolean) => {
      const header = document.querySelector('.nwLayout__header') as HTMLHeadElement;
      if(isFocus){
        if(header && header.style && window.innerWidth <= 767){
          header.style.zIndex = '1';
        } 
        setTimeout(()=>{
          const backBtn = document.querySelector('.nwSearch__header__input__icon--back') as HTMLDivElement; 
          backBtn.addEventListener('click', ()=>handleFocus(false));
        }, 300)
      } else {
        if(header && header.style){
          header.style.zIndex = '2';
        } 
        const backBtn = document.querySelector('.nwSearch__header__input__icon--back') as HTMLDivElement; 
        backBtn && backBtn.removeEventListener('click', ()=>handleFocus(false));
      }
    }

    const setInputRef = () => {
      const input = document.querySelector("input.nwSearch__header__input__input") as HTMLInputElement;
      ref.current = input;
      ref.current.onfocus = function(){handleFocus(true)};
    }

    useEffect(() => {
      if (title !== 'Rapid Search Bar') return;
      if(reloadCount < 1) {
        sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        window.location.reload();
      } else {
        sessionStorage.removeItem('reloadCount');
      }
    }, []);
  
    const handleClick = () => {
      demoUrl && window.open(demoUrl)
      if (title === 'Sustainability Screener'){
        setTimeout(()=>{demoUrl && window.open(demoUrl)}, 1000)
      }
    }

    useEffect(() => {
      window.scrollTo(0, 0);
      const scriptTag = document.createElement('script');
      if (title === 'Rapid Search Bar') {
        scriptTag.src = 'https://endowus-rapidsearch.newwealth.cloud/main.js';
        scriptTag.id = 'rapidSearch__script'
        scriptTag.onload = function(){setLoaded(true);};
        document.body.appendChild(scriptTag);
        setTimeout(setInputRef, 500)
      }
      return () => {
        // eslint-disable-next-line no-undef
        document.body.removeChild(scriptTag);
      };
    }, [])

  return (
    <div className="productBanner">
        <div className="productBanner__content">
            <div className="productBanner__content__text">
                <Title level={1} >{title}</Title>
                <Title level={2}>{desc && desc[0]}</Title>
                {/* {heading && <Paragraph>{heading}</Paragraph>} */}
                {!loaded && <Paragraph>
                    <ul>{featureList?.map((feature, i) =>
                            <li key={i}><CheckCircleFilled/>{feature}</li>
                    )}</ul>
                </Paragraph>}
                <div className="productBanner__content__button">
                {title !== 'Rapid Search Bar' && <Button type="primary" onClick={handleClick} disabled={disableAction}>
                  Live Demo
                </Button>}
                </div>
            </div>
            <div className={`productBanner__content__img ${title=='Rapid Search Bar'? 'productBanner__content__img--small':''}`}>
              {demoSrc && <img src={demoSrc} />}
            </div>
        </div>
        {title=='Rapid Search Bar' &&
          <>
            <Divider/>
            <div className='rapidSearchContainer' >
              <Title level={4}>LIVE DEMO: </Title>
              <div id="rapidSearch"/>
            </div>
          </>
        }
  </div>
  );
}

export default ProductBanner;
