import React, {ReactElement} from "react";
import { Typography } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './integration.scss';

const { Title, Paragraph } = Typography;

const NativeMobile: React.FC = (): ReactElement => {
    return (
        <div className="integration">
            <Title level={4}>Focus on native apps</Title>
            <Paragraph>
                Mobile apps will display mini-apps by using WebView components.   
            </Paragraph>
            <Paragraph>
            <span className="integration__text--bold">In this example, we use Flutter framework:</span>
            </Paragraph>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 1</span>: In Android Studio, create a new flutter app with default configuration. 
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 2</span>: Create main.dart file with following content: 
                <SyntaxHighlighter language="java" style={atomOneDark} lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} wrapLines={true}>
                    {`import 'dart:async';

import 'package:flutter/material.dart';
import 'package:webview_flutter/webview_flutter.dart';
import 'home_page.dart';

void main() {
runApp(MyApp());
}

class MyApp extends StatelessWidget {
final Completer<WebViewController> _controller = Completer<WebViewController>();

@override
Widget buid(BuildContext context) {
    return MaerialApp(
        title: 'Flutter - Micro Front',
        theme: ThemeData (
            primarySwatch: Colors.blue,
            visualDensity: VisualDensity.adaptivePlatformDensity,
        ),
        home: HomePage(),
    );
}
}`}
                </SyntaxHighlighter>
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 3</span>: Create home_page.dart file with the following content: 
                <br/>
                <SyntaxHighlighter language="java" style={atomOneDark} lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} wrapLines={true}>
                    {`import 'dart:async';

import 'package:flutter/material.dart';
import 'package:webview_flutter/webview_flutter.dart';

class HomePage extends StatelessWidget {
final Completer<WebViewController> _controller = Complete<WebViewController>();

@override
Widget build(BuildContext context) {
    return Scaffold(
        appBar: null,
        body: WebView(
            initialUrl: "https://clientname-miniapp.newwealth.cloud",
            javascriptMode: JavascriptMode.unrestricted,
            onWebViewCreated: (WebViewController webViewController) {
                _controller.complete(webViewController);
            },
        )
    );
}
}`}
                </SyntaxHighlighter>
            </Paragraph>
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">STEP 4</span>: Run app. 
            </Paragraph> 
            <br/>
            <Paragraph>
                <span className="integration__text--emphasis">Note</span>: To prevent security risk and potential vulnerabilities from previous releases, mobile apps should use the latest sdk.
            </Paragraph>  
        </div> 
    );
}

export default NativeMobile;
        